import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { CatalogServiceDto } from '@wix/bookings-uou-types';
import {
  ActionNames,
  BiServiceType,
  ReferralInfo,
  serviceListReferralInfo,
} from '../../utils/bi/consts';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigationTarget } from './consts';
import { WidgetConfig } from '../../../legacy/types';
import {
  getTrackingInfoForBookButtonClick,
  getTrackingInfoForNavigateToServicePageClick,
} from '@wix/bookings-analytics-adapter';
import { bookingsBookItClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { getResourceQueryParam } from '../../utils/queryParams/queryParams';
import { getLocationId } from '../../utils/getLocationId/getLocationId';
import { WidgetViewModel } from '../../viewModel/viewModel';
import { Basket } from '@wix/bookings-services-preferences-modal/types';
import {
  navigateToBookingsCalendarPage,
  navigateToBookingsServicePage,
} from '@wix/bookings-catalog-calendar-viewer-navigation';

export type NavigateToNextPageAction = ({
  service,
  target,
  referralInfo,
  basket,
}: {
  service?: CatalogServiceDto;
  target: NavigationTarget;
  referralInfo?: ReferralInfo;
  basket?: Basket;
}) => Promise<void>;

export const createNavigateToNextPageAction = ({
  config,
  wixSdkAdapter,
  flowAPI,
  widgetViewModel,
}: {
  config: WidgetConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  flowAPI: ControllerFlowAPI;
  widgetViewModel: WidgetViewModel;
}): NavigateToNextPageAction => {
  const {
    bi,
    experiments,
    controllerConfig: { wixCodeApi },
  } = flowAPI;
  const isRouterPrefixNavigationEnable = experiments?.enabled(
    'specs.bookings.RouterPrefixNavigation',
  );
  const isNavigateToServicePageWithNavigationModuleEnabled =
    experiments?.enabled(
      'specs.bookings.navigateToServicePageWithNavigationModule',
    );

  const isNavigateToCalendarPageWithNavigationModuleEnabled =
    experiments?.enabled(
      'specs.bookings.navigateToCalendarPageWithNavigationModule',
    );

  return async ({ basket, service, target, referralInfo }) => {
    const queryParam = getResourceQueryParam(wixSdkAdapter);
    const locationId = getLocationId(flowAPI, widgetViewModel);
    const optionalParams = {
      referral: serviceListReferralInfo,
      ...(queryParam.staff ? { staff: queryParam.staff } : {}),
      ...(queryParam.resource ? { resource: queryParam.resource } : {}),
      ...(locationId ? { location: locationId } : {}),
    };

    if (!service) {
      if (!basket) {
        console.error('both service and basket are missing');
        return;
      }
      return wixSdkAdapter.navigateToBookingsCalendarPage(
        basket,
        optionalParams,
        isRouterPrefixNavigationEnable,
      );
    }
    const data = {
      service,
      businessName: config.businessInfo.name!,
    };
    const trackingInfo =
      target === NavigationTarget.CALENDAR_PAGE
        ? getTrackingInfoForBookButtonClick(data)
        : getTrackingInfoForNavigateToServicePageClick(data);
    wixSdkAdapter.trackAnalytics(trackingInfo);

    bi?.report(
      bookingsBookItClick({
        service_id: service.id,
        type: BiServiceType[service.type],
        isPendingApproval: service.schedulePolicy.isPendingApprovalFlow,
        referralInfo,
        actionName: ActionNames[target],
      }),
    );
    try {
      switch (target) {
        case NavigationTarget.CALENDAR_PAGE:
          return isNavigateToCalendarPageWithNavigationModuleEnabled
            ? navigateToBookingsCalendarPage(wixCodeApi, {
                serviceSlugOrBasket: service.urlName,
                navigationContext: optionalParams,
              })
            : wixSdkAdapter.navigateToBookingsCalendarPage(
                service.urlName,
                optionalParams,
                isRouterPrefixNavigationEnable,
              );
        case NavigationTarget.SERVICE_PAGE:
          return isNavigateToServicePageWithNavigationModuleEnabled
            ? navigateToBookingsServicePage(wixCodeApi, {
                serviceSlug: service.dummy ? '' : service.urlName,
                ...optionalParams,
              })
            : wixSdkAdapter.navigateToBookingsServicePage(
                service.dummy ? '' : service.urlName,
                optionalParams,
                isRouterPrefixNavigationEnable,
              );
      }
    } catch (e) {
      console.error(e);
    }
  };
};
